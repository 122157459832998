var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"disabled":_vm.disabled},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{ref:"searchInput",attrs:{"outlined":"","dense":"","prepend-inner-icon":"search","placeholder":"Buscar opciones...","clearable":"","hide-details":"auto"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.visibleFields),function(field,key){
var _obj, _obj$1;
return _c('v-row',{key:key,staticClass:"field-container",class:( _obj = {}, _obj[("field-valtype-" + (_vm.getValueType(key).value))] = true, _obj ),attrs:{"no-gutters":""}},[_c('div',{class:( _obj$1 = {}, _obj$1[_vm.getValueType(key).color] = true, _obj$1 ),staticStyle:{"width":"2px"}}),(_vm.allowedValueTypes.length > 1)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 mr-3",staticStyle:{"border-radius":"0 0 5px 0"},attrs:{"dark":"","depressed":"","color":_vm.getValueType(key).color}},Object.assign({}, tooltip, menu)),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.getValueType(key).icon)}}),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.valueTypes),function(valType){return _c('v-list-item',{key:valType.value,attrs:{"dense":"","color":valType.color},on:{"click":function($event){return _vm.setValueType(key, valType.value)}}},[_c('v-icon',{attrs:{"left":"","color":valType.color},domProps:{"textContent":_vm._s(valType.icon)}}),_c('v-list-item-title',{domProps:{"textContent":_vm._s(valType.label)}})],1)}),1)],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.getValueType(key).label)}})]):_vm._e(),_c('v-col',{staticClass:"field-value"},[_c('v-slide-y-transition',{attrs:{"group":"","mode":"out-in"}},[(
                _vm.value[key] &&
                [
                  'parameter',
                  'parameterWithFallback',
                  'env',
                  'user'
                ].includes(_vm.value[key].type)
              )?_c('Field',{key:"parameter",staticClass:"my-2 parameter-input",attrs:{"name":key,"schema":{
                type: 'string',
                label: field.label,
                fieldOptions: {
                  color: _vm.getValueType(key).color,
                  prefix: 'Parámetro:'
                },
                newDesign: true
              },"clearable":""},model:{value:(_vm.value[key].parameterName),callback:function ($$v) {_vm.$set(_vm.value[key], "parameterName", $$v)},expression:"value[key].parameterName"}}):_vm._e(),(
                !_vm.value[key] ||
                !_vm.value[key].type ||
                _vm.value[key].type === 'fixed' ||
                _vm.value[key].type === 'parameterWithFallback'
              )?_c('Field',{key:"fixed",staticClass:"my-2",attrs:{"name":key,"schema":Object.assign({}, field,
                {label:
                  _vm.value[key] && _vm.value[key].type === 'parameterWithFallback'
                    ? 'Valor Por Defecto'
                    : field.label,
                fieldOptions: Object.assign({}, {color: _vm.getValueType(key).color},
                  field.fieldOptions),
                newDesign: true}),"value":_vm.getFixedValue(key),"error-messages":_vm.validationErrors[key],"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables},on:{"input":function($event){return _vm.setFixedValue(key, $event)}}}):_vm._e(),(_vm.value[key] && _vm.value[key].type === 'indicator')?_c('Field',{key:"indicator",staticClass:"my-2",attrs:{"name":key,"schema":{
                type: 'indicatorSelect',
                label: field.label,
                fieldOptions: {
                  showChip: true,
                  hideIcon: true
                },
                newDesign: true
              },"v-model":_vm.value[key].indicatorId,"error-messages":_vm.validationErrors[key],"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables}}):_vm._e()],1)],1)],1)}),1)],1),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }